import React, { useEffect, useMemo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import { Box, Flex, Space, Text } from "@mantine/core";
import classes from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCoinDetails } from "../../utils/fetchCoinDetails";
import { useAppSelector } from "../../redux-app/hooks";
import { useForm } from "react-hook-form";
import { forms } from "../../forms";
import { yupResolver } from "@hookform/resolvers/yup";
import Wallet from "../deposit/components/wallet/Wallet";
import InputField from "../../ui/input-field";
import Button from "../../ui/button";
import { getCurrencyExchangeRate } from "../../utils/getCurrencyExchangeRate";
import { useBuyTokenMutation } from "../../hooks/buy-token/mutation/useBuyToken.mutation";
import ErrorText from "../../ui/error-text/ErrorText";

const BuyTokenScreen = () => {
  const { code } = useParams<{ code: TCurrencyData["code"] }>();
  const { currencies } = useAppSelector((state) => state.currencyRates);
  const { usdtWallet } = useAppSelector((state) => state.user.userData);
  const { blockchainNetworkTypes } = useAppSelector(
    (state) => state.settings.data
  );
  const navigate = useNavigate();

  const { isPending, mutateAsync } = useBuyTokenMutation();

  const { code: coin, icon } = useMemo(() => {
    return fetchCoinDetails(
      (code?.toUpperCase() as TCurrencyData["code"]) ?? "USDT"
    );
  }, [code]);

  const rate = useMemo(() => {
    return currencies.find((value) => value.code === coin)?.offerPrice ?? 0;
  }, [coin, currencies]);

  const livePrice = useMemo(() => {
    return currencies.find((value) => value.code === coin)?.rate ?? 0;
  }, [coin, currencies]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...forms.buyToken.values,
      amount: 1,
    },
    resolver: yupResolver(forms.buyToken.validation(1, usdtWallet.totalAmount)),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  const onSubmit = async (values: typeof forms.buyToken.values) => {
    const res = await mutateAsync({
      ...values,
      coin,
      discountedAltPrice: rate,
      livePrice: livePrice,
    });

    if (res.status === "success") {
      navigate("/history");
      reset();
      customAlert.show({
        type: "success",
        title: res.title,
        message: res.message,
      });
    } else {
      customAlert.show({
        type: "error",
        title: res.data.title,
        message: res.data.message,
      });
    }
  };

  const userAmount = watch("amount");

  useEffect(() => {
    setValue("amount", userAmount);
    setValue(
      "amountInUsdt",
      Number(getCurrencyExchangeRate(userAmount, rate, "token").toFixed(2))
    );
  }, [rate, setValue, userAmount]);

  return (
    <Container>
      <BackBtnHeader heading="Buy Token" />
      <Space my={30} />
      <Wallet />

      <Flex mt={25} align={"center"} justify={"space-between"}>
        <Flex align={"center"}>
          {icon}
          <div style={{ marginLeft: 10 }} className={classes.title}>
            Buy {coin}
          </div>
        </Flex>
        <div className={`${classes.offPrice}`}>
          {Number(rate).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          USDT
        </div>
      </Flex>

      <Flex w={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Flex my={25} direction={"column"}>
            <InputField
              control={control}
              label={`Deposit amount in ${code?.toUpperCase()}`}
              name={"amount"}
              placeholder={`Enter amount in ${code?.toUpperCase()}`}
              onChange={(e) =>
                setValue(
                  "amountInUsdt",
                  Number(
                    getCurrencyExchangeRate(
                      Number(e.target.value),
                      rate,
                      "token"
                    ).toFixed(2)
                  )
                )
              }
            />
          </Flex>
          <Flex my={25} direction={"column"}>
            <Text
              size="sm"
              variant="text"
              c={"#7e8088"}
            >{`USDT deducted from wallet`}</Text>

            <InputField
              disabled
              control={control}
              label={""}
              name={"amountInUsdt"}
              placeholder={`Enter amount in USDT`}
              onChange={(e) =>
                setValue(
                  "amount",
                  Number(
                    getCurrencyExchangeRate(
                      Number(e.target.value),
                      rate,
                      "usdt"
                    ).toFixed(2)
                  )
                )
              }
            />
          </Flex>

          <Flex direction={"column"} my={25}>
            <p className={classes.label}> {"Select blockchain type"} </p>
            <Flex>
              {blockchainNetworkTypes.map((networkType) => {
                if (networkType.type === coin) {
                  return (
                    <Box
                      key={networkType._id}
                      onClick={() =>
                        setValue("networkType", networkType.label.toLowerCase())
                      }
                      className={`${classes.blockchainBox} ${
                        watch("networkType") ===
                          networkType.label.toLowerCase() &&
                        classes.activeBlockchain
                      }`}
                    >
                      {networkType.label}
                    </Box>
                  );
                } else {
                  return null;
                }
              })}

              {/* {coin === "ETH" && (
                <Box
                  onClick={() => setValue("networkType", "erc20")}
                  className={`${classes.blockchainBox} ${
                    watch("networkType") === "erc20" && classes.activeBlockchain
                  }`}
                >
                  ERC-20
                </Box>
              )}
              {coin === "SOL" && (
                <Box
                  onClick={() => setValue("networkType", "sol")}
                  className={`${classes.blockchainBox} ${
                    watch("networkType") === "sol" && classes.activeBlockchain
                  }`}
                >
                  SOL
                </Box>
              )}

              {coin === "BTC" && (
                <Box
                  onClick={() => setValue("networkType", "btc")}
                  className={`${classes.blockchainBox} ${
                    watch("networkType") === "btc" && classes.activeBlockchain
                  }`}
                >
                  BTC
                </Box>
              )} */}
            </Flex>
            {errors?.networkType && (
              <span className={classes.errorText}>
                {errors?.networkType?.message as string}
              </span>
            )}
          </Flex>

          <Flex my={25} direction={"column"}>
            <InputField
              control={control}
              label={`Blockchain Address`}
              name={"blockchainAddress"}
              placeholder={`Enter your blockchain address`}
            />
          </Flex>

          <ErrorText text="Important: Please ensure the wallet address you enter is correct. Double-check before submitting. If an incorrect address is used, the assets may be lost and cannot be recovered." />

          <Flex my={20}>
            <Button loading={isPending} title={"Buy"} type="submit" />
          </Flex>
        </form>
      </Flex>
    </Container>
  );
};

export default BuyTokenScreen;
