import React, { memo } from "react";
import classes from "./index.module.scss";
import { Box, Flex } from "@mantine/core";
import { SVG } from "../../../assets/svg";
import moment from "moment";

interface IHistoryCard {
  data: TDepositRequest;
}

const HistoryCard: React.FC<IHistoryCard> = ({
  data: { amount, createdAt, status },
}) => {
  const getColor = () => {
    switch (status) {
      case "approved":
        return "#25c866";
      case "rejected":
        return "#d32f2f";
      case "new":
        return "#F7CB73";
    }
  };
  return (
    <Box className={classes.row}>
      <div className={classes.svgIconContainer}>{SVG.usdt}</div>
      <Flex w={"100%"} justify={"space-between"} align={"center"}>
        <div className={classes.title}>
          <p style={{ margin: 0 }}> {"Deposit"} </p>
          <span> {moment(createdAt).format("lll")} </span>
        </div>
        <div className={classes.rate}>
          <p className={classes.coinRate}>
            <span className={classes.positive}>+</span>{" "}
            {amount.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}{" "}
            USDT
          </p>
          <p style={{ color: getColor() }} className={classes.status}>
            {status}
          </p>
        </div>
      </Flex>
    </Box>
  );
};

export default memo(HistoryCard);
