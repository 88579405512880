import * as Yup from "yup";
import { store } from "../../redux-app/store";

export const depositValidation = () => {
  const { usdtDeposit } =
    store.getState().settings.data.minimumTransactionLimits;

  return Yup.object().shape({
    amount: Yup.number()
      .typeError("Enter a deposit amount")
      .required("Enter a deposit amount")
      .min(usdtDeposit, "Minimum deposit amount is " + usdtDeposit),
    receipt: Yup.string().required("payment receipt is required"),
    transactionId: Yup.string().required(
      "Please enter or paste copied transaction hash"
    ),
    method: Yup.string().required("Select payment method for deposit"),
  });
};
