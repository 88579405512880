import React, { memo } from 'react'
import DepositScreen from '../components/deposit/DepositScreen'

const Deposit = () => {
  return (
   <DepositScreen/>
  )
}

export default memo(Deposit)
