import { Outlet } from "react-router-dom";
import FetchUpdateSettings from "../socket-events/FetchUpdateSettings";
import LatestCoinsRate from "../socket-events/LatestCoinsRate";
import { useLatestCurrencyRateQuery } from "../hooks/currency/query/useLatestCurrencyRate.query";
import { useSettingsQuery } from "../hooks/settings/query/useSettings.query";
import { useCheckAuthenticated } from "../hooks/auth/useCheckAuthenthicated";
import PreLoader from "../components/pre-loader";
import WalletUpdated from "../socket-events/WalletUpdated";
import { useIsAuthenticated } from "react-auth-kit";
import { useEffect } from "react";
import { requestPermission } from "../services/notification.service";

const TheLayout = () => {
  const { isLoading, refetch: reloadLatestRate } =
    useLatestCurrencyRateQuery(false);

  const { refetch: reloadSettings } = useSettingsQuery(false);
  const { refetch: reloadProfile } = useCheckAuthenticated(false);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated()) {
      reloadLatestRate();
      reloadSettings();
      reloadProfile();
    }
  }, [isAuthenticated, reloadLatestRate, reloadSettings, reloadProfile]);

  useEffect(() => {
    requestPermission();
  }, []);

  if (isLoading) {
    return <PreLoader />;
  }
  return (
    <>
      <Outlet />
      <FetchUpdateSettings />
      <LatestCoinsRate />
      <WalletUpdated />
    </>
  );
};

export default TheLayout;
