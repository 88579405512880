import { Modal } from "@mantine/core";
import React, { FC } from "react";
import classes from "./index.module.scss";

interface IConfirmationModal {
  show: boolean;
  handelClose: () => void;
  agreeOnclick: () => void;
  data: {
    title: string;
    message: string;
    agreeBtnTitle: string;
  };
}

const ConfirmationModal: FC<IConfirmationModal> = ({
  handelClose,
  show,
  agreeOnclick,
  data: { title, message, agreeBtnTitle },
}) => {
  return (
    <Modal
      centered
      size={350}
      withCloseButton={false}
      opened={show}
      onClose={handelClose}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
      styles={{ content: { backgroundColor: "#222223" }, body: { padding: 0 } }}
    >
      <div className={`${classes.success_box}`}>
        <div className={classes.header}>
          <h4 className={classes.heading}> {title} </h4>
          <p className={classes.message}> {message} </p>
        </div>
        <div className={classes.btnSection}>
          <button
            onClick={handelClose}
            className={`${classes.btn} ${classes.borderRight}`}
          >
            Cancel
          </button>
          <button
            onClick={agreeOnclick}
            className={`${classes.btn} ${classes.btnActive}`}
          >
            {agreeBtnTitle}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
