import React, { memo, useMemo } from "react";
import classes from "./index.module.scss";
import { fetchCoinDetails } from "../../utils/fetchCoinDetails";
import { Box, Flex } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { checkInsufficientBalance } from "../../middleware/checkInsufficientBalance";
import { SVG } from "../../assets/svg";

interface ISingleCoin {
  data: TCurrencyData;
}

const SingleCoin: React.FC<ISingleCoin> = ({
  data: { code, rate, offerPrice },
}) => {
  const coinDetails = useMemo(() => {
    return fetchCoinDetails(code);
  }, [code]);

  const navigate = useNavigate();

  return (
    <Box
      className={classes.row}
      style={{ cursor: "pointer" }}
      onClick={() =>
        checkInsufficientBalance(offerPrice, navigate, () =>
          navigate(`/buy-token/${code.toLowerCase()}`)
        )
      }
    >
      {coinDetails.icon}
      <div className={classes.content}>
        <div className={classes.title}>
          <p> {code} </p>
          <span> {coinDetails.name} </span>
        </div>
        <div className={classes.rateChange}>
          <Flex
            align={"center"}
            justify={"center"}
            className={`${classes.liveRate} ${classes.headerCol}`}
          >
            {SVG.tether({ color: "#ffffff85", size: 12 })}
            {Number(rate).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}{" "}
          </Flex>
          <div className={`${classes.offPrice} ${classes.headerCol}`}>
            <Flex
              align={"center"}
              justify={"center"}
              className={`${classes.offPrice}`}
            >
              {SVG.tether({ color: "#25c866", size: 12 })}
              {Number(offerPrice).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </Flex>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default memo(SingleCoin);
