import React from "react";
import classes from "./index.module.scss";
import MethodCard from "../method-card";
import { IMAGES } from "../../../../assets/images";
import { UseFormReturn } from "react-hook-form";
import MethodsForm from "../methods-form/MethodsForm";

interface IPaymentMethodsForm {
  handler: UseFormReturn<any>;
}

const PaymentMethodsForm: React.FC<IPaymentMethodsForm> = ({ handler }) => {
  const { watch, setValue, formState } = handler;
  const errors = formState.errors;
  return (
    <div className={classes.root}>
      <h5 className={classes.heading}>Choose Your Payment Method</h5>
      <div className={classes.methodContainer}>
        <MethodCard
          image={IMAGES.tether_logo}
          onClick={() => {
            setValue("method", "trc20");
          }}
          title="TRC-20"
          value={watch("method")}
          method="trc20"
        />
        <MethodCard
          image={IMAGES.tether_logo}
          onClick={() => setValue("method", "bep20")}
          title="BEP-20"
          method="bep20"
          value={watch("method")}
        />
      </div>
      {errors?.method && (
        <span className={classes.errorText}>
          {errors?.method?.message as string}
        </span>
      )}
      {watch("method") && <MethodsForm handler={handler} />}
    </div>
  );
};

export default PaymentMethodsForm;
