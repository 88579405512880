import { NavigateFunction } from "react-router-dom";
import { store } from "../redux-app/store";

export const checkInsufficientBalance = (
  amount: number,
  navigate: NavigateFunction,
  handleNavigate: () => void
) => {
  const { usdtWallet } = store.getState().user.userData;

  if (usdtWallet.totalAmount < amount) {
    customAlert.show({
      type: "error",
      title: "Insufficient Amount",
      message:
        "You have insufficient balance in your wallet. Ensure you have enough USDT to buy at least one unit of this cryptocurrency. Please deposit the required amount to proceed.",
      okTitle: "Yes",
      cancelTitle: "No",
      okCallBack: () => navigate("/deposit"),
    });
  } else {
    handleNavigate();
  }
};
