import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const get = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.deposit.FETCH_DEPOSIT_HISTORY,
    method: "GET",
  });
  return response;
};

export const useDepositHistoryQuery = () => {
  return useQuery({
    queryKey: [apiUrls.deposit.FETCH_DEPOSIT_HISTORY],
    queryFn: () => get(),
  });
};
