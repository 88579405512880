import React, { memo } from "react";
import classes from "./index.module.scss";
import { IMAGES } from "../../../../assets/images";

interface IProps {
  title: string;
  image: string;
  onClick: () => void;
  value: string;
  method: string;
}

const MethodCard:React.FC<IProps> = ({image,method,onClick,title,value}) => {
    
  return (
    <button type="button" className={`${classes.root} ${value === method && classes.activeCard}`} onClick={()=>onClick()}>
      <img className={classes.iconImg} src={image ??IMAGES.tether_logo} alt="" />
      <p className={classes.title}> {title} </p>
    </button>
  );
};

export default memo(MethodCard);
