import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../../redux-app/hooks";

import { ActionIcon, CopyButton, Flex, rem, Tooltip } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import classes from "./styles.module.scss";
import ErrorText from "../../../../ui/error-text/ErrorText";

export const getRandomIndex = (length: number) =>
  Math.floor(Math.random() * length);

const Bep20PaymentDetails = () => {
  const { detailsChangingTime, bep20 } = useAppSelector(
    (state) => state.settings.data.paymentMethods
  );

  const [currentIndex, setCurrentIndex] = useState(
    getRandomIndex(bep20.length)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(getRandomIndex(bep20.length));
    }, detailsChangingTime * 60000); // 3 minutes in milliseconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, [bep20.length, detailsChangingTime]);

  const accountDetails = useMemo(
    () => bep20[currentIndex],
    [currentIndex, bep20]
  );

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.addressLabelCol}>
          <span className={classes.label}> Blockchain Network </span>
        </div>
        <Flex align={"center"}>
          <span className={classes.label}>
            {accountDetails?.blockchainNetwork}
          </span>
          <CopyButton value={accountDetails?.blockchainNetwork} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "white"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Flex>
      </div>
      <div className={classes.row}>
        <div className={classes.addressLabelCol}>
          <span className={classes.label}> Deposit Address </span>
        </div>
        <div className={classes.addressValueCol}>
          <span className={classes.label}>
            {accountDetails?.depositAddress}
          </span>
          <CopyButton value={accountDetails?.depositAddress} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "white"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </div>
      </div>
      <div className={classes.qrContainer}>
        <img className={classes.qrImg} src={accountDetails?.qrCode} alt="" />
      </div>

      <ErrorText text="Important: Ensure you copy the correct wallet address for USDT deposits. Wallet addresses are updated occasionally due to high transaction volumes. Verify the address each time before placing a deposit request to avoid loss of funds." />
    </div>
  );
};

export default Bep20PaymentDetails;
