import React from "react";
import { UseFormReturn } from "react-hook-form";
import classes from "./index.module.scss";
import InputField from "../../../../ui/input-field";
import Trc20PaymentDetails from "../trc20-payment-details/Trc20PaymentDetails";
import Bep20PaymentDetails from "../bep20-payment-details copy/Bep20PaymentDetails";
import FileDropzone from "../../../../ui/file-dropzone/FileDropzone";

interface IProps {
  handler: UseFormReturn<any>;
}

const MethodsForm: React.FC<IProps> = ({ handler }) => {
  const { watch } = handler;

  const paymentMethod = watch("method");

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Make your payment on the details below</h2>
      <div className={classes.container}>
        {paymentMethod === "trc20" && <Trc20PaymentDetails />}
        {paymentMethod === "bep20" && <Bep20PaymentDetails />}

        <FileDropzone handler={handler} name="receipt" />

        <div>
          <InputField
            border={true}
            control={handler.control}
            label={"Deposit transaction hash"}
            name={"transactionId"}
            placeholder={"Enter or paste transaction hash"}
          />
        </div>
      </div>
    </div>
  );
};

export default MethodsForm;
