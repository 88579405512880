import { SVG } from "../assets/svg";

export const fetchCoinDetails = (coin: TCurrencyData["code"]) => {
  switch (coin) {
    case "BTC":
      return {
        icon: SVG.bitcoin,
        name: "Bitcoin",
        background: "#ab00e7",
        code: coin,
      };
    case "ETH":
      return {
        icon: SVG.ethereum,
        name: "Ethereum",
        background: "#0082FE",
        code: coin,
      };
    case "BNB":
      return {
        icon: SVG.binance,
        name: "Binance",
        background: "#D7AA23",
        code: coin,
      };
    case "SOL":
      return {
        icon: SVG.solana,
        name: "Solana",
        background: "#8E46F3",
        code: coin,
      };
    default:
      return {
        icon: SVG.usdt,
        name: "USDT",
        background: "#ab00e7",
        code: coin,
      };
  }
};
