import { memo, useState } from "react";
import Container from "../../../container";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import BackBtnHeader from "../../backBtn-header";
import { Link } from "react-router-dom";
import { IMAGES } from "../../../assets/images";
import ROUTES from "../../../enum/routes";
import SignUpModal from "../../models/signUp-modal";
import { useSignUpMutation } from "../../../hooks/auth/useSignUp.mutation";
import { showNotification } from "@mantine/notifications";

const SignUpScreen = () => {
  const [loginKeyModal, setLoginKeyModal] = useState<{
    data:
      | {
          username: string;
          loginKey: string;
        }
      | undefined;
    show: boolean;
  }>({
    data: undefined,
    show: false,
  });

  const { isPending, mutateAsync } = useSignUpMutation();

  const onSubmit = async () => {
    const res = await mutateAsync();
    if (res.status === "success") {
      setLoginKeyModal({
        data: res.data,
        show: true,
      });
    } else {
      showNotification({
        message: res.data?.title,
        color: "red",
      });
    }
  };

  return (
    <Container enableBg={false}>
      <BackBtnHeader />
      <div className={classes.root}>
        <div className={classes.contentCenter}>
          <img className={classes.logo} src={IMAGES.logo_text} alt="img" />
        </div>
        <h2 className={classes.heading}>Create your account</h2>
        <div className={classes.btnContainer}>
          <Button
            loading={isPending}
            disabled={isPending}
            type={"button"}
            onClick={onSubmit}
            title={"Generate your private key"}
          />
          <p className={classes.medium_text}>
            <span>Important</span> -Your Discounted Alts decentralized wallet
            generates a unique private key - this is the sole access to your
            crypto. Losing this key means permanent, irrecoverable loss of all
            assets. Please safeguard this key diligently. Discounted Alts cannot
            retrieve or reset a lost key. Protect your finances by storing this
            private information securely.
          </p>
        </div>

        <p className={classes.smallText}>
          Already have a Account? &ensp;
          <Link to={ROUTES.LOGIN}>Enter your private key</Link>
        </p>
      </div>
      {loginKeyModal.show && loginKeyModal.data && (
        <SignUpModal
          data={loginKeyModal.data}
          show={loginKeyModal.show}
          handelClose={() => setLoginKeyModal({ data: undefined, show: false })}
        />
      )}
    </Container>
  );
};

export default memo(SignUpScreen);
