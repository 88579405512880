import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const create = async (
  data: Omit<TBuyTokens, "username" | "userId" | "status" | "rejectReason">
) => {
  const response: TServerResponse = await request({
    url: apiUrls.buyToken.CREATE_BUY_TOKEN,
    method: "POST",
    data,
  });
  return response;
};

export const useBuyTokenMutation = () => {
  return useMutation({ mutationFn: create });
};
