import { ActionIcon, Box, Flex, Group, Image, Text } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconUpload, IconX } from "@tabler/icons-react";
import React, { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import classes from "./styles.module.scss";

interface IFileDropzone {
  handler: UseFormReturn<any>;
  name: string;
}

const FileDropzone: FC<IFileDropzone> = ({ handler, name }) => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = handler;

  return (
    <Box my={25}>
      <Dropzone
        activateOnClick={!watch(name)}
        styles={{
          root: {
            background: "transparent",
          },
        }}
        mih={175}
        onDrop={(files) => {
          if (files.length > 0) {
            setValue(name, files[0] as File);
          }
        }}
        maxSize={2 * 1024 ** 2}
        accept={IMAGE_MIME_TYPE}
        maxFiles={1}
      >
        {watch(name) && (
          <Flex
            w={"25%"}
            m={"auto"}
            justify={"center"}
            style={{ position: "relative" }}
          >
            <Image
              src={URL.createObjectURL(watch(name))}
              onLoad={() =>
                URL.revokeObjectURL(URL.createObjectURL(watch(name)))
              }
            />
            <ActionIcon
              style={{
                pointerEvents: "all",
                position: "absolute",
                right: -15,
                top: -10,
              }}
              onClick={() => setValue(name, undefined)}
              variant="filled"
              color="red"
              radius={"xl"}
            >
              <IconX />
            </ActionIcon>
          </Flex>
        )}

        {!watch(name) && (
          <Group
            align="center"
            gap="sm"
            justify="center"
            mih={175}
            style={{ flexDirection: "column" }}
          >
            <IconUpload color="white" size={35} />
            <Text c={"#fff"} size="md" inline>
              Upload or drag payment screenshot
            </Text>
            <Text size="xs" c="dimmed" inline mt={7}>
              file should not exceed 2mb
            </Text>
          </Group>
        )}
      </Dropzone>
      {errors[name] && (
        <span className={classes.errorText}>
          {errors[name]?.message as string}
        </span>
      )}
    </Box>
  );
};

export default FileDropzone;
