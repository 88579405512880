import { Center, Drawer } from "@mantine/core";
import React, { useEffect, useMemo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import { useDepositHistoryQuery } from "../../hooks/deposit/query/useDepositHistory.query";
import HistoryCard from "./history-card/HistoryCard";

interface IDepositHistory {
  opened: boolean;
  close: () => void;
}

const DepositHistory: React.FC<IDepositHistory> = ({ close, opened }) => {
  const { isLoading, data, refetch } = useDepositHistoryQuery();

  const history: TDepositRequest[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (opened) refetch();
  }, [opened, refetch]);

  return (
    <Drawer
      size={"100%"}
      withCloseButton={false}
      opened={opened}
      onClose={close}
      overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      transitionProps={{
        transition: "fade-left",
        duration: 200,
        timingFunction: "linear",
      }}
      position="right"
      styles={{
        content: { backgroundColor: "#11150f" },
        body: { padding: 0 },
      }}
    >
      <Container loading={isLoading}>
        <BackBtnHeader heading="Deposit History" handleBack={close} />

        {history.length === 0 && (
          <Center mih={"80vh"}>
            <h4>No history found</h4>
          </Center>
        )}
        {history.map((request) => (
          <HistoryCard key={request._id} data={request} />
        ))}
      </Container>
    </Drawer>
  );
};

export default DepositHistory;
