import React, { memo } from "react";
import classes from "./index.module.scss";
import SingleCoin from "./SingleCoin";
import { useAppSelector } from "../../redux-app/hooks";

const CoinRate: React.FC = () => {
  const { currencies } = useAppSelector((state) => state.currencyRates);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>Name</div>
        <div className={classes.headerPrice}>
          <span className={classes.headerCol}>Live Price</span>
          <span className={classes.headerCol}>Our Price</span>
        </div>
      </div>
      {currencies.map((currency) => (
        <SingleCoin key={currency._id} data={currency} />
      ))}
    </div>
  );
};

export default memo(CoinRate);
