import { memo } from 'react'
import BoardingTwoScreen from '../components/splash-screen/BoardingTwoScreen'

const BoardingTwo = () => {
  return (
    <BoardingTwoScreen/>
  )
}

export default memo(BoardingTwo)
