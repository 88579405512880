import React, { memo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import InputField from "../../ui/input-field";
import { useForm } from "react-hook-form";
import PaymentMethodsForm from "./components/payment-methodsForm/PaymentMethodsForm";
import { forms } from "../../forms";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../ui/button";
import { ActionIcon, Box } from "@mantine/core";
import Wallet from "./components/wallet/Wallet";
import { useAppSelector } from "../../redux-app/hooks";
import { useCreateDepositRequestMutation } from "../../hooks/deposit/mutation/useCreateDepositRequest.mutation";
import { notifications } from "@mantine/notifications";
import { IconHistory } from "@tabler/icons-react";
import DepositHistory from "../deposit-history/DepositHistory";
import { useDisclosure } from "@mantine/hooks";

const DepositScreen = () => {
  const [opened, { open, close }] = useDisclosure();
  const { minimumTransactionLimits } = useAppSelector(
    (state) => state.settings.data
  );

  const { isPending, mutateAsync } = useCreateDepositRequestMutation();
  const handler = useForm({
    defaultValues: {
      ...forms.deposit.values,
      amount: minimumTransactionLimits.usdtDeposit,
    },
    resolver: yupResolver<any>(forms.deposit.validation()),
    reValidateMode: "onChange",
    mode: "all",
  });
  const onSubmit = async (values: typeof forms.deposit.values) => {
    const formData = new FormData();

    if (!values.receipt) {
      handler.setError("receipt", {
        message: "Payment receipt is required",
      });
      return;
    }

    formData.append("receipt", handler.watch("receipt"));
    formData.append("amount", values.amount.toString());
    formData.append("method", values.method);
    formData.append("transactionId", values.transactionId);

    const res = await mutateAsync(formData);

    if (res.status === "success") {
      open();
      handler.reset();
    } else {
      notifications.show({
        color: "red",
        message: res.data.message,
      });
    }
  };
  return (
    <Container>
      <BackBtnHeader
        heading={"Deposit USDT"}
        rightSection={
          <ActionIcon onClick={open} color="white" variant="subtle">
            <IconHistory size={25} />
          </ActionIcon>
        }
      />
      <Wallet />

      <div>
        <form onSubmit={handler.handleSubmit(onSubmit)}>
          <InputField
            control={handler.control}
            label={"Deposit amount in USDT"}
            name={"amount"}
            placeholder={"Deposit amount in USDT"}
          />
          <PaymentMethodsForm handler={handler} />

          <Box my={20}>
            <Button
              loading={isPending}
              title={"Confirm Deposit"}
              type="submit"
            />
          </Box>
        </form>
      </div>
      <DepositHistory close={close} opened={opened} />
    </Container>
  );
};

export default memo(DepositScreen);
