enum ROUTES {
  WELCOME = "/welcome",
  LOGIN = "/login",
  SIGN_UP = "/sign-up",
  BOARDING_ONE = "/boarding-1",
  BOARDING_TWO = "/boarding-2",
  HOME = "/",
  BUY = "/buy",
  BUY_TOKEN = "/buy-token/:code",
  DEPOSIT = "/deposit",
  PROFILE = "/profile",
  HISTORY = "/history",
}

export default ROUTES;
