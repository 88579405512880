import { useMutation } from "@tanstack/react-query";
import { request } from "../../services/axios.service";

import { apiUrls } from "../apiUrls";
import { forms } from "../../forms";

const login = async (data: typeof forms.login.values) => {
  const response: TServerResponse = await request({
    url: apiUrls.auth.LOGIN,
    method: "POST",
    data: {
      loginKey: data.loginKey,
    },
  });
  return response;
};

export const useLoginMutation = () => {
  return useMutation({ mutationFn: login });
};
