import { userApiPrefix } from "../../constant";

export const authUrls = {
  LOGIN: "/auth/user/sign-in",
  GET_PROFILE: userApiPrefix,
  DEACTIVATE_ACCOUNT: userApiPrefix + "deactivate-account",
  SIGN_UP: "/auth/user/sign-up",
  GENERATE_FCM_TOKEN: userApiPrefix + "generate-firebase-token",
  FETCH_NOTIFICATIONS: userApiPrefix + "notifications",
};
