import React from "react";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import { Modal } from "@mantine/core";

interface IProps {
  show: boolean;
  handelClose: () => void;
}

const PrivacyPolicyModel: React.FC<IProps> = ({ show, handelClose }) => {
  return (
    <Modal
      centered
      size={335}
      withCloseButton={false}
      opened={show}
      onClose={handelClose}
      classNames={{
        content: classes.modal_content,
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
    >
      <div>
        <h3 className={classes.heading}>Privacy Policy</h3>
        <div className={classes.text_small}>
          <ol className={classes.orderList}>
            <li>
              Anonymous Registration
              <p>
                Users can create an account on Discounted Alts without providing
                personal information. Only a unique 20-digit private key is
                generated for account access.
              </p>
            </li>
            <li>
              Data Collection
              <p>
                Discounted Alts does not collect or store any personal
                information from users. All activities on the platform are
                anonymous.
              </p>
            </li>
            <li>
              Data Security
              <p>
                The platform uses advanced encryption to secure all transactions
                and private keys. Discounted Alts does not have access to users’
                private keys or any other sensitive information.
              </p>
            </li>
            <li>
              User Responsibility
              <p>
                Users are responsible for safeguarding their private keys.
                Discounted Alts is not liable for lost or compromised keys, or
                for unauthorized access resulting from user negligence.
              </p>
            </li>
            <li>
              Transaction Records
              <p>
                Discounted Alts retains records of transactions to ensure
                service accuracy and to address potential disputes. These
                records do not contain personal information.
              </p>
            </li>
            <li>
              Third-Party Services
              <p>
                Discounted Alts may use third-party services to facilitate
                transactions (e.g., blockchain networks). Users should review
                the privacy policies of these third-party services.
              </p>
            </li>
            <li>
              Cookies and Tracking
              <p>
                Discounted Alts does not use cookies or tracking technologies to
                monitor user activity on the platform.
              </p>
            </li>
            <li>
              Legal Compliance
              <p>
                While Discounted Alts aims to maintain user anonymity, the
                platform will comply with legal requests for information as
                required by applicable laws.
              </p>
            </li>
            <li>
              Updates to Privacy Policy
              <p>
                Discounted Alts reserves the right to update this Privacy Policy
                at any time. Changes will be posted on the platform, and
                continued use constitutes acceptance of the updated policy.
              </p>
            </li>
            <li>
              Contact for Support
              <p>
                For questions or concerns regarding privacy, users can contact
                our support team via the Telegram link provided in the
                application.
              </p>
            </li>
          </ol>
        </div>
        {/* <div className={classes.privacyContainer}>
          <div className={classes.form_group}>
            <input type="checkbox" id="html" />
            <label style={{ marginLeft: 10 }} htmlFor="html">
              I agree to the Privacy policy
            </label>
          </div>
        </div> */}
        <div className={classes.acceptBtnContainer}>
          <Button title="I Accept" type={"button"} onClick={handelClose} />
        </div>
      </div>
    </Modal>
  );
};

export default PrivacyPolicyModel;
