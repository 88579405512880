import React, { memo } from "react";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import { Modal } from "@mantine/core";

interface IProps {
  show: boolean;
  handelClose: () => void;
}

const TermCondition: React.FC<IProps> = ({ handelClose, show }) => {
  return (
    <Modal
      centered
      size={343}
      withCloseButton={false}
      opened={show}
      onClose={handelClose}
      classNames={{
        content: classes.modal_content,
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
    >
      <div className={classes.modal_content}>
        <div>
          <h3 className={classes.heading}>Terms and Conditions</h3>
          <div className={classes.text_small}>
            <ol className={classes.orderList}>
              <li>
                <p>
                  By using Discounted Alts, you agree to these Terms and
                  Conditions.
                </p>
              </li>
              <li>
                <p>
                  Discounted Alts is available worldwide with no age or
                  geographic restrictions.
                </p>
              </li>
              <li>
                <p>
                  Users can create an anonymous account and will receive a
                  unique 20-digit private key for access.
                </p>
              </li>
              <li>
                <p>
                  The private key is the only method to access your account;
                  Discounted Alts is not responsible for lost keys.
                </p>
              </li>
              <li>
                <p>
                  Large transactions exceeding 10 BTC may result in your crypto
                  wallet being flagged, which could lead to asset loss.
                  Discounted Alts is not responsible for any issues arising from
                  such transactions.
                </p>
              </li>
              <li>
                <p>
                  Users can deposit USDT via TRC20 or BEP20 networks; network
                  fees apply.
                </p>
              </li>
              <li>
                <p>
                  Discounted Alts allows users to buy Bitcoin, BNB, Solana, and
                  Ethereum at discounted rates.
                </p>
              </li>
              <li>
                <p>
                  Purchased cryptocurrencies can be withdrawn to any preferred
                  wallet or platform.
                </p>
              </li>
              <li>
                <p>
                  Discounted Alts charges no additional fees beyond network
                  fees.
                </p>
              </li>
              <li>
                <p>
                  Users must provide a correct wallet address when buying
                  cryptocurrencies; Discounted Alts is not responsible for funds
                  sent to an incorrect address after the transaction is
                  approved.
                </p>
              </li>
              <li>
                <p>
                  Fraudulent activities are strictly prohibited and will result
                  in account termination.
                </p>
              </li>
              <li>
                <p>
                  Cryptocurrency trading involves risks, including the potential
                  loss of your entire investment.
                </p>
              </li>
              <li>
                <p>
                  Discounted Alts not liable for any losses, damages, or
                  technical issues.
                </p>
              </li>
              <li>
                <p>
                  For support, contact our Telegram support team via the
                  application.
                </p>
              </li>
              <li>
                <p>
                  Discounted Alts reserves the right to modify these terms at
                  any time.
                </p>
              </li>
            </ol>
          </div>
          {/* <div className={classes.privacyContainer}>
            <div className={classes.form_group}>
              <input type="checkbox" id="html-Term" />
              <label htmlFor="html-Term">I agree to the Term of service.</label>
            </div>
          </div> */}
          <div className={classes.acceptBtnContainer}>
            <Button title="I Accept" type={"button"} onClick={handelClose} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(TermCondition);
