import { Text } from "@mantine/core";
import React, { FC, memo } from "react";

interface IErrorText {
  text: string;
}

const ErrorText: FC<IErrorText> = ({ text }) => {
  return (
    <Text my={20} style={{ fontSize: 10 }} c="#d32f2f">
      {text}
    </Text>
  );
};

export default memo(ErrorText);
