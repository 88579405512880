import React, { memo } from "react";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import { SVG } from "../../../assets/svg";
import {
  ActionIcon,
  CopyButton,
  Flex,
  Modal,
  rem,
  Tooltip,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../enum/routes";
import { IconCheck, IconCopy, IconDownload } from "@tabler/icons-react";

interface IProps {
  show: boolean;
  handelClose: () => void;
  data: {
    username: string;
    loginKey: string;
  };
}

const SignUpModal: React.FC<IProps> = ({
  handelClose,
  show,
  data: { loginKey, username },
}) => {
  const navigate = useNavigate();

  const handleSaveFile = () => {
    // Create a Blob from the text
    const blob = new Blob([loginKey], { type: "text/plain" });
    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);
    // Create an anchor element and trigger a download
    const a = document.createElement("a");
    a.href = url;
    a.download = "loginKey.txt";
    document.body.appendChild(a);
    a.click();
    // Clean up and remove the anchor
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Modal
      centered
      size={"lg"}
      withCloseButton={false}
      opened={show}
      onClose={() => {}}
      classNames={{
        content: classes.modal_content,
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
    >
      <div>
        <div style={{ textAlign: "center" }}>{SVG.check_circle}</div>
        <h3 className={classes.heading}>Congratulations</h3>
        <p className={classes.subHeading}>
          You have successfully created an account
        </p>
        <div>
          <p className={classes.text_small}>
            Welcome, <span className={classes.boldText}> {username} </span>
          </p>
          <div className={classes.copyContainer}>
            <Flex align={"center"} justify={"center"} w={"100%"} my={20}>
              <b className={classes.boldText}>{loginKey}</b>
              <CopyButton value={loginKey} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? "Copied" : "Copy"}
                    withArrow
                    position="right"
                  >
                    <ActionIcon
                      color={copied ? "teal" : "white"}
                      variant="subtle"
                      onClick={copy}
                    >
                      {copied ? (
                        <IconCheck color="#25c866" style={{ width: rem(16) }} />
                      ) : (
                        <IconCopy color="#25c866" style={{ width: rem(16) }} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>

              <Tooltip label="Download key">
                <ActionIcon
                  color="#fff"
                  variant="subtle"
                  onClick={handleSaveFile}
                >
                  <IconDownload style={{ width: rem(18) }} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </div>
        </div>

        <div className={classes.acceptBtnContainer}>
          <Button
            title="Login"
            type={"button"}
            onClick={() => {
              navigate(ROUTES.LOGIN);
              handelClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(SignUpModal);
