import { useEffect } from "react";
import { useWebsocket } from "../services/socket.service";
import { useLatestCurrencyRateQuery } from "../hooks/currency/query/useLatestCurrencyRate.query";

const LatestCoinsRate = () => {
  const { socket } = useWebsocket();

  const { refetch } = useLatestCurrencyRateQuery(false);

  useEffect(() => {
    if (socket) {
      socket.on("latest-rate-updated", (latestRates) => {
        refetch();
      });
    }
    return () => {
      socket?.off("latest-rate-updated");
    };
  }, [socket, refetch]);

  return null;
};

export default LatestCoinsRate;
