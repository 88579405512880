import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducer/user/user-reducer";
import settingSlice from "./reducer/settings/settings-reducer";
import currencyRatesSlice from "./reducer/currency-rates/currency-rates-reducer";

export const store = configureStore({
  reducer: {
    user: userSlice,
    settings: settingSlice,
    currencyRates: currencyRatesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
