export const getCurrencyExchangeRate = (
  amount: number,
  tokenRate: number,
  type: "usdt" | "token"
) => {
  if (type === "token") {
    return amount * tokenRate;
  } else {
    return amount / tokenRate;
  }
};
