import { Center } from "@mantine/core";

import React, { useMemo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import HistoryCard from "./history-card/HistoryCard";
import { useBuyTokenHistoryQuery } from "../../hooks/buy-token/query/useBuyTokenHistory.query";
import FooterHeader from "../footer-header/FooterHeader";

const HistoryScreen = () => {
  const { isLoading, data } = useBuyTokenHistoryQuery();

  const history: TBuyTokens[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <Container loading={isLoading}>
      <BackBtnHeader heading="History" />

      {history.length === 0 && (
        <Center mih={"80vh"}>
          <h4>No history found</h4>
        </Center>
      )}
      {history.map((request) => (
        <HistoryCard key={request._id} data={request} />
      ))}
      <FooterHeader />
    </Container>
  );
};

export default HistoryScreen;
