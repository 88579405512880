import { memo } from "react";
import Container from "../../../container";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../../ui/input-field";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import BackBtnHeader from "../../backBtn-header";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../../../enum/routes";
import { IMAGES } from "../../../assets/images";
import { forms } from "../../../forms";
import { useLoginMutation } from "../../../hooks/auth/useLoginMutation";
import { useSignIn } from "react-auth-kit";
import { notifications } from "@mantine/notifications";

const LoginScreen = () => {
  const Navigate = useNavigate();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: forms.login.values,
    resolver: yupResolver(forms.login.validation),
    reValidateMode: "onChange",
  });
  const signIn = useSignIn();

  const { mutateAsync, isPending } = useLoginMutation();

  const onSubmit = async (data: typeof forms.login.values) => {
    const res = await mutateAsync(data);
    if (
      res.status === "success" &&
      signIn({
        token: res.data.token,
        expiresIn: res.data.expiresIn,
        tokenType: "Bearer",
        authState: res.data.authUserState,
      })
    ) {
      sessionStorage.setItem("token", res.data.token);
      reset();
      Navigate(ROUTES.HOME);
    } else {
      notifications.show({
        message: res.data?.message,
        color: "red",
      });
    }
  };

  return (
    <Container enableBg={false}>
      <BackBtnHeader />
      <div className={classes.root}>
        <div className={classes.contentCenter}>
          <img className={classes.logo} src={IMAGES.logo_text} alt="img" />
        </div>
        <h2 className={classes.heading}>Login</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.inputContainer}>
            <InputField
              label={"Enter your private key"}
              placeholder={"xxxxxxxxxxxxxxxxxxxx"}
              name={"loginKey"}
              control={control}
            />
          </div>

          <Button loading={isPending} type={"submit"} title={"Login"} />
        </form>
        <p className={classes.smallText}>
          Doesn't have an account? &ensp;
          <Link to="/sign-up">Generate your private key</Link>
        </p>
      </div>
    </Container>
  );
};

export default memo(LoginScreen);
