import { memo } from "react";
import Container from "../../container";
import classes from "./index.module.scss";
import { IMAGES } from "../../assets/images";
import { Link } from "react-router-dom";
import PrivacyPolicyTag from "./privacy-policy-tag";
import ROUTES from "../../enum/routes";

const BoardingScreen = () => {
  return (
    <Container enableBg={false}>
      <div className={classes.container}>
        <div className={`${classes.banner_boarding} ${classes.mt_30}`}>
          <img src={IMAGES.boarding2} alt="img" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="375"
            height="440"
            viewBox="0 0 390 440"
            fill="none"
          >
            <g filter="url(#filter0_f_1_12302)">
              <path
                d="M195 220C160 340 270 340 195 340C120 340 230 360 60 220C-110 80 120 100 195 100C270 100 430 100 295 220C220 300 140 100 195 220Z"
                fill="url(#paint0_radial_1_12302)"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1_12302"
                x="-50"
                y="0.925049"
                width="558"
                height="438.317"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="50"
                  result="effect1_foregroundBlur_1_12302"
                />
              </filter>
              <radialGradient
                id="paint0_radial_1_12302"
                cx="50%"
                cy="50%"
                r="50%"
                fx="50%"
                fy="50%"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#CD8617" />
                <stop offset="1" stopColor="#CD8617" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className={classes.content}>
          <h2 className={classes.heading}>Your Security, Your Control</h2>
          <p className={`${classes.medium_text} ${classes.mt_8}`}>
            Access your account with a unique private key—no personal details
            needed. Enjoy complete control and privacy in a decentralized
            environment.
          </p>
          <Link to={ROUTES.BOARDING_TWO} className={classes.nextBtn}>
            Next{" "}
          </Link>
          <PrivacyPolicyTag />
        </div>
      </div>
    </Container>
  );
};

export default memo(BoardingScreen);
