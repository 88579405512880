import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserState {
  userData: TUser;
}

const initialState: IUserState = {
  userData: {
    _id: "",
    isActive: true,
    loginKey: "",
    suspended: false,
    usdtWallet: {
      withdraw: 0,
      deposit: 0,
      lockedAmount: 0,
      totalAmount: 0,
    },
    username: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateProfileData: (state, action: PayloadAction<TUser>) => {
      const { usdtWallet } = action.payload;

      state.userData = {
        ...action.payload,
        usdtWallet: {
          ...usdtWallet,
          totalAmount:
            usdtWallet.deposit + usdtWallet.withdraw + usdtWallet.lockedAmount,
        },
      };
    },
  },
});

export const { updateProfileData } = userSlice.actions;

export default userSlice.reducer;
