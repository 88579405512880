import avatar from "./avt2.jpg";
import boarding1 from "./boarding1.jpg";
// import boarding2 from "./boarding2.jpg";
import boarding3 from "./boarding3.jpg";
import logo from "./logo.png";
import logo_text from "./logo-text.png";
import logo48 from "./logo/48.png";
import tether_logo from "./tether-logo.png";
import dummy_qr_code from "./dummy-qr-code.png";
import tether from "./tether.svg";
import tetherPrimary from "./tether-primary.svg";
import support from "./support.png";
import logoBlur from "./logoBlur.png";
import welcome from "./welcome.png";
import boarding2 from "./boarding-2.png";
import person from "./person.png";

export const IMAGES = {
  avatar: avatar,
  boarding1,
  boarding2,
  boarding3,
  logo,
  logo_text,
  logo48,
  tether_logo,
  dummy_qr_code,
  tether,
  tetherPrimary,
  support,
  logoBlur,
  welcome,
  person,
};
