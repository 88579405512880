import * as yup from "yup";

export const buyTokenValidation = (
  minimumAmount: number,
  maximumAmount: number
) =>
  yup.object({
    amount: yup
      .number()
      .typeError("Enter a valid buy amount")
      .min(minimumAmount, `Minimum buy amount is ${minimumAmount}.`)
      .integer("The amount cannot be a fraction.")
      .required("Enter a buy amount"),
    amountInUsdt: yup
      .number()
      .typeError("Enter a valid buy amount")
      .required("Enter a buy amount")
      .max(
        maximumAmount,
        `Insufficient amount entered. Wallet Balance: ${maximumAmount.toFixed(
          2
        )}`
      ),
    networkType: yup.string().required("Network type field is required"),
    blockchainAddress: yup
      .string()
      .required("Blockchain address field is required"),
  });
