import  { memo } from 'react'
import HomeScreen from '../components/home/HomeScreen'

const Home = () => {
  return (
    <HomeScreen/>
  )
}

export default memo(Home)
