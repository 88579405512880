import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IGraphData {
  [key: string]: number[];
}
interface ICurrencyState {
  currencies: TCurrencyData[];
  graphData: IGraphData;
}

const initialState: ICurrencyState = {
  currencies: [],
  graphData: {},
};

export const currencyRatesSlice = createSlice({
  name: "currency-rates",
  initialState,
  reducers: {
    updateLatestRates: (state, action: PayloadAction<TCurrencyData[]>) => {
      state.currencies = action.payload.filter(
        (currency) => currency.code !== "USDT"
      );
    },

    updateLatestGraph: (state, action: PayloadAction<IGraphData>) => {
      state.graphData = action.payload;
    },
  },
});

export const { updateLatestRates, updateLatestGraph } =
  currencyRatesSlice.actions;

export default currencyRatesSlice.reducer;
