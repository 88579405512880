import React, { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classes from "./index.module.scss";
import { SVG } from "../../assets/svg";
import ROUTES from "../../enum/routes";

const FooterHeader: React.FC = () => {
  const location = useLocation();

  return (
    <div className={classes.root}>
      <ul className={classes.inner_bar}>
        <li className={location.pathname === ROUTES.HOME ? "active" : ""}>
          <NavLink to={ROUTES.HOME} className={classes.navLink}>
            {location.pathname === ROUTES.HOME ? SVG.home_active : SVG.home}
            Home
          </NavLink>
        </li>
        <li className={location.pathname === ROUTES.BUY ? "active" : ""}>
          <NavLink to={ROUTES.BUY} className={classes.navLink}>
            {location.pathname === ROUTES.BUY
              ? SVG.wallet_nav_active
              : SVG.wallet_nav}
            Buy
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.HISTORY} className={classes.navLink}>
            {location.pathname === ROUTES.HISTORY
              ? SVG.historyActive
              : SVG.history}
            History
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.PROFILE} className={classes.navLink}>
            {location.pathname === ROUTES.PROFILE
              ? SVG.profile_nav_active
              : SVG.profile_nav}
            Profile
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default memo(FooterHeader);
