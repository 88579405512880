import { useEffect } from "react";
import { useWebsocket } from "../services/socket.service";
import { useSettingsQuery } from "../hooks/settings/query/useSettings.query";

const FetchUpdateSettings = () => {
  const { socket } = useWebsocket();
  const { refetch } = useSettingsQuery(false);

  useEffect(() => {
    if (socket) {
      socket.on("settings-updated", () => {
        refetch();
      });
    }
    return () => {
      socket?.off("settings-updated");
    };
  }, [socket, refetch]);

  return null;
};

export default FetchUpdateSettings;
