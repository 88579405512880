import React from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import FooterHeader from "../footer-header/FooterHeader";
import TokensList from "./components/TokensList";
import Wallet from "../deposit/components/wallet/Wallet";

const BuyCurrencyDashboardScreen = () => {
  return (
    <Container>
      <BackBtnHeader heading={"Buy Tokens"} />
      <Wallet />
      <TokensList />
      <FooterHeader />
    </Container>
  );
};

export default BuyCurrencyDashboardScreen;
