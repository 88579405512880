import React from "react";
import classes from "../../index.module.scss";


interface ISingleNotification {
  data: {
    message: string;
    time: string;
    active?: boolean;
  };
}

const SingleNotification: React.FC<ISingleNotification> = ({ data }) => {
  const { message, active, time } = data;
  return (
    <div className={classes.card}>
      <div className={classes.headingSection}>
        <p className={classes.description}>{message}</p>

        {active && <div className={classes.dot} />}
      </div>
      <span className={classes.time}> {time} </span>
    </div>
  );
};

export default SingleNotification;
