import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { deleteAllCookies } from "./getCookies.service";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});
/* eslint-disable */
export const request = async (options: AxiosRequestConfig<any>) => {
  const token = sessionStorage.getItem("token");

  client.defaults.headers.common.authorization = `Bearer ${token}`;

  const onSuccess = (response: AxiosResponse) => response.data;
  /* eslint-disable */
  const onError = (error: any) => {
    if (error.response.status === 401) {
      deleteAllCookies();
      window.location.pathname = "/welcome";
    }
    if (error.response.status >= 500) {
      alert(error.response?.data?.message);
    }

    return error.response;
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
