import { memo } from "react";
import classes from "./index.module.scss";
import { IMAGES } from "../../../assets/images";
import { SVG } from "../../../assets/svg";
import { useDisclosure } from "@mantine/hooks";
import NotificationScreen from "../../notification/NotificationScreen";
import { ActionIcon, Flex } from "@mantine/core";
import { IconHeadset } from "@tabler/icons-react";
import { useAppSelector } from "../../../redux-app/hooks";

const TheHeader = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { telegram } = useAppSelector(
    (state) => state.settings.data.socialLinks
  );
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div>
            <div className={classes.profile}>
              <img src={IMAGES.person} alt="img" className={classes.avatar} />
            </div>
          </div>
          <Flex align={"center"}>
            <ActionIcon
              component="a"
              target="_blank"
              href={telegram}
              size={"lg"}
              variant="transparent"
              color="#fff"
            >
              <IconHeadset size={35} />
            </ActionIcon>
            <button onClick={open} className={classes.notificationContainer}>
              {/* <span className={classes.count}>2</span> */}
              {SVG.notification}
            </button>
          </Flex>
        </div>
      </div>
      <NotificationScreen close={close} opened={opened} />
    </>
  );
};

export default memo(TheHeader);
