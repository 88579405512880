import React, { memo, useMemo } from "react";
import classes from "./index.module.scss";
import LineChartSVG from "./LineChartSVG";
import { Box, Flex } from "@mantine/core";
import { fetchCoinDetails } from "../../../utils/fetchCoinDetails";
import { useAppSelector } from "../../../redux-app/hooks";

interface IMarketCard {
  data: TCurrencyData;
}

const MarketCard: React.FC<IMarketCard> = ({
  data: { code, rate, offerPercent, offerPrice },
}) => {
  const { graphData } = useAppSelector((state) => state.currencyRates);

  const coinDetails = useMemo(() => {
    return fetchCoinDetails(code);
  }, [code]);

  const sampleData = graphData[code];

  return (
    <div className={classes.swipe_slide}>
      <Box className={classes.coin_box}>
        <div className={classes.coin_logo}>
          {coinDetails.icon}
          <div className={classes.title}>
            <p> {coinDetails.name} </p>
            <span> {code} </span>
          </div>
        </div>
        <LineChartSVG data={sampleData} strokeColor={coinDetails.background} />
        <div className={classes.coin_price}>
          <Flex>
            <Flex align={"center"} className={classes.livePrice}>
              $
              {Number(rate).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{" "}
            </Flex>
            <Flex align={"center"} className={classes.offerPrice}>
              $
              {Number(offerPrice).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}{" "}
            </Flex>
          </Flex>
        </div>
        <div
          className={`${classes.blur} `}
          style={{ backgroundColor: coinDetails.background }}
        />
        <Flex align={"center"} className={classes.offerTag}>
          <span className={classes.changesValue}>{offerPercent}% Off</span>
        </Flex>
      </Box>
    </div>
  );
};

export default memo(MarketCard);
