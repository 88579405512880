import React from 'react'
import ProfileScreen from '../components/Profile/ProfileScreen'

const Profile = () => {
  return (
   <ProfileScreen/>
  )
}

export default Profile
