import React, { useState } from "react";
import Container from "../../container";
import classes from "./index.module.scss";
import { SVG } from "../../assets/svg";
import FooterHeader from "../footer-header/FooterHeader";
import PrivacyPolicyModel from "../models/privacy-policy";
import TermCondition from "../models/term-condition/TermCondition";
import ConfirmationModal from "../../ui/modals/confirmation-modal/ConfirmationModal";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux-app/hooks";
import { ActionIcon, CopyButton, Flex, Tooltip } from "@mantine/core";
import { IconCheck, IconCopy, IconEye, IconEyeOff } from "@tabler/icons-react";
import ProfileHeader from "./components/ProfileHeader";
import { useDisclosure } from "@mantine/hooks";
import DeactivationModal from "./components/deactivation-modal/DeactivationModal";

const logoutData = {
  title: "Log Out",
  message: "Are you sure you want to sign out?",
  agreeBtnTitle: "Log Out",
};
const deactivateData = {
  title: "Deactivate account",
  message: "Are you sure you want to deactivate your account?",
  agreeBtnTitle: "Deactivate",
};

const ProfileScreen = () => {
  const { loginKey, username } = useAppSelector((state) => state.user.userData);
  const [opened, { close, open }] = useDisclosure();

  const [isLoginKeyVisible, setLoginKeyVisibility] = useState(false);
  const [modal, setModal] = useState({
    termAndCondition: false,
    privacyModal: false,
    logout: false,
    deactivate: false,
  });

  const handleCloseModal = () => {
    setModal({
      termAndCondition: false,
      privacyModal: false,
      logout: false,
      deactivate: false,
    });
  };

  const signOut = useSignOut();
  const navigate = useNavigate();

  const { privacyModal, termAndCondition, logout, deactivate } = modal;

  return (
    <Container>
      <ProfileHeader heading="Profile" />
      <div className={classes.container}>
        <ul className={`${classes.contentContainer} ${classes.bottomLine}`}>
          <li>
            <Flex
              align={"center"}
              className={`${classes.itemContainer} ${classes.itemContainerResponsive}`}
            >
              <Flex className={classes.smallText}>Private key</Flex>
              <Flex wrap={"wrap"} justify={"flex-end"}>
                <span
                  className={`${classes.valueContainer} ${classes.valueContainerResponsive}`}
                >
                  {isLoginKeyVisible ? loginKey : "••••••••••••••"}
                </span>
                <div className={classes.CopyButton}>
                  <ActionIcon
                    onClick={() => setLoginKeyVisibility(!isLoginKeyVisible)}
                    variant="subtle"
                    className={classes.visibilityToggle}
                  >
                    {isLoginKeyVisible ? (
                      <IconEyeOff size={14} color="#7e8088" />
                    ) : (
                      <IconEye size={14} color="#7e8088" />
                    )}
                  </ActionIcon>
                </div>
                {isLoginKeyVisible && (
                  <div className={classes.CopyButton}>
                    <CopyButton value={loginKey} timeout={2000}>
                      {({ copied, copy }) => (
                        <Tooltip
                          label={copied ? "Copied" : "Copy"}
                          withArrow
                          position="right"
                        >
                          <ActionIcon
                            color={copied ? "teal" : "white"}
                            variant="subtle"
                            onClick={copy}
                          >
                            {copied ? (
                              <IconCheck size={14} color="#7e8088" />
                            ) : (
                              <IconCopy color="#7e8088" size={14} />
                            )}
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </CopyButton>
                  </div>
                )}
              </Flex>
            </Flex>
          </li>
          <li>
            <button
              className={`${classes.itemContainer} ${classes.itemContainerResponsive}`}
            >
              <p className={classes.smallText}>Name</p>
              <Flex wrap={"wrap"}>
                <span
                  className={`${classes.valueContainer} ${classes.valueContainerResponsive}`}
                >
                  {username}
                </span>
                <div className={classes.CopyButton}>
                  <CopyButton value={username} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? "Copied" : "Copy"}
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? "teal" : "white"}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck color="#7e8088" style={{ width: 14 }} />
                          ) : (
                            <IconCopy color="#7e8088" style={{ width: 14 }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </div>
              </Flex>
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                setModal((state) => ({ ...state, privacyModal: true }))
              }
              className={classes.itemContainer}
            >
              <p className={classes.smallText}>Privacy policy</p>
              <span className={classes.valueContainer}>{SVG.back_arrow}</span>
            </button>
          </li>
          <li>
            <button
              className={classes.itemContainer}
              onClick={() =>
                setModal((state) => ({ ...state, termAndCondition: true }))
              }
            >
              <p className={classes.smallText}>Term & conditions</p>
              <span className={classes.valueContainer}>{SVG.back_arrow}</span>
            </button>
          </li>
        </ul>
        <div className={classes.btnSection}>
          <button
            className={classes.actionBtn}
            onClick={() => setModal((state) => ({ ...state, logout: true }))}
          >
            Log out
          </button>
          <button
            className={classes.actionBtn}
            onClick={() =>
              setModal((state) => ({ ...state, deactivate: true }))
            }
          >
            Deactivate account
          </button>
        </div>
      </div>
      <FooterHeader />
      <PrivacyPolicyModel show={privacyModal} handelClose={handleCloseModal} />
      <TermCondition show={termAndCondition} handelClose={handleCloseModal} />
      <ConfirmationModal
        data={logoutData}
        show={logout}
        handelClose={handleCloseModal}
        agreeOnclick={() => {
          signOut();
          sessionStorage.clear();
          navigate("/login");
        }}
      />
      <ConfirmationModal
        data={deactivateData}
        show={deactivate}
        handelClose={handleCloseModal}
        agreeOnclick={() => {
          handleCloseModal();
          open();
        }}
      />
      <DeactivationModal handelClose={close} show={opened} />
    </Container>
  );
};

export default ProfileScreen;
