import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "react-auth-kit";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import { MantineProvider } from "@mantine/core";
import { Notifications, showNotification } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { Provider } from "react-redux";
import { store } from "./redux-app/store";
import GlobalAlert from "./ui/alert/GlobalAlert";
import { useEffect } from "react";
import { onMessage } from "@firebase/messaging";
import { messaging } from "./firebase/firebase";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  useEffect(() => {
    onMessage(messaging, (payload) => {
      showNotification({
        message: payload.notification?.body,
        color: "cyan",
      });

      new Notification(payload.notification?.title ?? "Title", {
        body: payload.notification?.body,
        icon: "company-logo.png",
      });
    });
  }, []);
  return (
    <Provider store={store}>
      <MantineProvider>
        <GlobalAlert>
          <AuthProvider
            authType={"localstorage"}
            authName={`${process.env.REACT_APP_AUTH_NAME}`}
            cookieDomain={window.location.hostname}
            cookieSecure={window.location.protocol === "https:"}
          >
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              <ReactQueryDevtools
                position="bottom"
                buttonPosition="bottom-right"
                initialIsOpen={false}
              />
            </QueryClientProvider>
          </AuthProvider>
          <Notifications position="top-right" />
        </GlobalAlert>
      </MantineProvider>
    </Provider>
  );
};

export default App;
