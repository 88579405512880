import React, { memo } from "react";
import Container from "../../container";
import classes from "./index.module.scss";
import TheHeader from "./header/TheHeader";
import WalletCard from "./wallet-card/WalletCard";
import Market from "./market/Market";
import CryptoRating from "./crypto-rating/CryptoRating";
import FooterHeader from "../footer-header/FooterHeader";

const HomeScreen: React.FC = () => {
  return (
    <Container>
      <TheHeader />
      <div className={classes.root}>
        <WalletCard />
        <Market />
        <CryptoRating />
      </div>
      <FooterHeader />
    </Container>
  );
};

export default memo(HomeScreen);
